import { PinterestSelectors } from '@sqs/websites-constants';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/pinterest.css';
/**
 * @module squarespace-pinterest
 */
YUI.add('squarespace-pinterest', function(Y) {

  var Pinterest = Y.namespace('Squarespace.Pinterest');

  Pinterest.bind = function(pinterestOverlayOptions) {

    if (Y.Lang.isObject(pinterestOverlayOptions)) {

      var config = {};
      config.hover = true;

      var SIZES = {
        round: {
          small: '16',
          large: '32'
        },

        rect: {
          small: '20',
          large: '28'
        }
      };

      var SHAPE = pinterestOverlayOptions.shape;

      if (!Y.Lang.isValue(SHAPE)) {
        SHAPE = 'rect';
      }

      var SIZE = pinterestOverlayOptions.size;

      if (!Y.Lang.isValue(SIZE)) {
        SIZE = 'small';
      }



      config.shape = SHAPE;
      config.height = SIZES[SHAPE][SIZE];

      if (pinterestOverlayOptions.mode === 'enabled_for_blogs') {
        config.ancestorSelectorTest = PinterestSelectors.BLOGS.join(',');
      } else if (pinterestOverlayOptions.mode === 'enabled_for_blogs_and_pages') {
        config.ancestorSelectorTest = PinterestSelectors.BLOGS_AND_PAGES.join(',');
      }

      // adjust how SIX-15620 works
      // This changes the behavior so that the images of products in the list view reference
      // the item view url so that when the image is pinned it brings with it a link to purchasing
      // and pricing information.

      var prefixesToCrawlAndCorrectPinUrl = ['#productList', '.ProductList', '.productlist'];
      prefixesToCrawlAndCorrectPinUrl.forEach(function(selectorPrefix) {
        if (config.ancestorSelectorTest.includes(selectorPrefix)) {
          Y.all([
            selectorPrefix + ' a[href][data-item-id]',
            selectorPrefix + ' a.hentry[href]'
          ].join(',')).each(function(anchorNode) {
            var itemViewUrl = anchorNode.getAttribute('href');
            if (
              Y.Lang.isString(itemViewUrl) &&
              itemViewUrl[0] === '/' &&
              Y.Lang.isString(window.location.origin)
            ) {
              itemViewUrl = window.location.origin + itemViewUrl;
            }

            anchorNode.all('img').each(function(imgNode) {
              imgNode.setAttribute('data-pin-url', itemViewUrl);
            });
          });
        }
      });

      Y.config.win.PINTEREST.f.init(config);
    }

  };

  Y.config.win.Squarespace.onInitialize(Y, function() {
    Pinterest.bind(Y.Object.getValue(Y.config.win.Static,
      'SQUARESPACE_CONTEXT.websiteSettings.pinterestOverlayOptions'.split('.')));
  });

}, '1.0', {
  requires: [
    'thirdparty-pinterest'
  ]
});

